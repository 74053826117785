import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { history } from '../_helpers';
import logo from "../assets/images/landing/white-logo.png";
import coke from "../assets/images/landing/heading/coke.png";
import now from "../assets/images/landing/heading/now.png";
import userwithcooler from "../assets/images/landing/user-with-cooler.png";
import phoneimage from "../assets/images/landing/phoneImage.png";
import playstore from "../assets/images/landing/playstore.png";
import apple from "../assets/images/landing/apple.png";
import "../assets/scss/landing.scss";
import { Trans } from 'react-i18next';
import { config } from '../_config';
import queryString from 'query-string';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { encryptData } from '../_helpers/encryptData';
import { isAndroid, isIOS } from "react-device-detector";
import { getAppId } from "../_helpers/getAppId";
const ApplicationTypeId = getAppId();

export default class ContinueOption extends Component {
    constructor(props){
        super(props)
        document.title=`Coke & Go ${config.countryName} | ContinueOption`;
        CapturePage({hitType:"pageview",page:"/ContinueOption"})  
        
        const urlpart = this.props.location.search.slice(1)
        let {qr:qrcode="", coolerType="",assetType=""} = queryString.parse(this.props.location.search);
        if (qrcode!='') {
            localStorage.setItem("qrcode", encryptData(qrcode));
        }
        this.qrcode = qrcode;
        // if(ApplicationTypeId==12 && (coolerType.toLowerCase()=="televend"||assetType.toLowerCase()=="televend")){
        //     history.push(`/continueoption-tvm?${urlpart}`)
        // }
        if (ApplicationTypeId == 20) {
            fetch("https://api.ipify.org?format=json")
                .then((json) => { return json.json() })
                .then(async (result) => {
                    const ipAddress = result.ip;
                    const pageName = window.location.href;
                    const applicationTypeId = ApplicationTypeId;
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        body: new URLSearchParams({ ipAddress, applicationTypeId, pageName }),
                    };
        
                    await fetch(`${config.envUrl}VendingAppApi/appSiteHitLog`, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            console.log(data);
                            if (isAndroid) {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.grabtaxi.passenger&hl=en_SG&pli=1";
                            } else if (isIOS) {
                                window.location.href = "https://apps.apple.com/sg/app/grab-taxi-ride-food-delivery/id647268330";
                            } else {
                                window.location.href = "https://play.google.com/store/apps/details?id=com.grabtaxi.passenger&hl=en_SG&pli=1";
                            }
                        })
                        .catch((error) => {
                            console.error("Error logging site hit:", error);
                        });
                });
        }

        else{
            this.redirectionForCCA()
        }

        this.handleRedirect = this.handleRedirect.bind(this);
        this.redirectionForCCA = this.redirectionForCCA.bind(this);
    }
    handleRedirect(){
        let checkOnboarding = localStorage.getItem('onBoarding');
        if(checkOnboarding){            
            history.push('/Link');
        }
        else{            
            history.push('/introSlider1');
        }
    }

    async redirectionForCCA (){
        if (ApplicationTypeId == 12) {
            window.location.href=`https://stories.onelink.me/URjs/gk6ey1eh`;
        }
        else if(ApplicationTypeId==24){
            window.location.href=`https://coke.onelink.me/ogvI/8allmd6s`;
        }
        else if(ApplicationTypeId==32){
            window.location.href=`https://stories.onelink.me/sdlR/yeept2fj`;
        }
        else{
            history.push('/introSlider1');
        }
    }
    render() {
        if(ApplicationTypeId==12 || ApplicationTypeId==24 || ApplicationTypeId==32){
            return null
        }
        if (ApplicationTypeId === 20) {
            return null; 
        }
        return (
            <>
                <div className="main-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-Header text-center text-md-left">
                                    <div className="logo">
                                        <img src={logo} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-theme">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12" style={{minHeight:'200px'}}>
                                    <h1 className="landing_title mb-0"><Trans i18nKey="landingTitle">Bebidas Coca-Cola geladinhas a hora que você quiser</Trans></h1>
                                    <div className="headingdiv text-center">
                                        <img src={coke} />
                                        <img src={now} />
                                    </div>
                                    <div className="headingOption text-center mt-4">
                                        {/* <button className="btn btn-round bg-white">Download App</button>
                                        <p className="text-white my-2">Or</p>
                                        <p><Link to="/introSlider1" className="text-white pb-2">Continue on the Web</Link></p> */}
                                        <div className="mt-3 download">
                                            <img src={playstore} />
                                            <img src={apple} className="ml-2" />
                                            <p className="text-white my-2"><Trans i18nKey="or">ou</Trans></p>
                                            <p><Link to="/introSlider1" className="text-white pb-2"><Trans i18nKey="continueWeb">continue no site</Trans></Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="MainSection">
                        <div className="container">
                            <div className="row flex-column-reverse flex-md-row">
                                <div className="col-md-6">
                                    <div className="px-4 cokedesc" >
                                        <div className="row">
                                            <div className="col-3">
                                                <img src={userwithcooler} />
                                            </div>
                                            <div className="col-9 coketitle">
                                                <h4 className="theme-font">CokeNow</h4>
                                                <span className=""><Trans i18nKey="unlockRefreshment"></Trans></span>
                                            </div>
                                            <div className="col-12">
                                                <h5 className="theme-font mt-3">Use o aplicativo para:</h5>
                                                <ul className="contentlist">
                                                    <li>Baixe o aplicativo Coke Now</li>
                                                    <li>Realize seu cadastro</li>
                                                    <li>Escaneie o QR Code para abrir a geladeira</li>
                                                    <li>Escolha seus produtos e feche a porta para realizar a compra</li>
                                                    <li>Rápido, prático e fácil!</li>
                                                </ul>
                                                <h6 className="theme-font mt-5">Baixe agora:</h6>

                                                <div className="mt-3 download">
                                                    <img src={playstore} />
                                                    <img src={apple} className="ml-2" />
                                                    <p className="mt-3" style={{fontWeight:500}}>ou <Link to="/introSlider1" className="text-dark" style={{textDecoration:'underline'}}>continue no site</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="phoneImage">
                                        {/* <img src={phoneimage} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2 text-center">
                                    <img src={logo} />
                                    <p className="my-4 text-uppercase text-white">© 2021 the Coca-cola company. All rights reserved.</p>                                
                                </div>
                                <div className="col-md-12 text-center">
                                <ul className="footerList">
                                        <li>
                                            <a href="https://us.coca-cola.com/support/contact-us"><Trans i18nKey="ContactUs"></Trans></a>
                                        </li>
                                        <li>
                                            <a href="https://us.coca-cola.com/privacy-policy"><Trans i18nKey="Privacynav"></Trans></a>
                                        </li>
                                        <li>
                                            <a href="https://us.coca-cola.com/privacy-policy"><Trans i18nKey="PersonalInfo"></Trans></a>
                                        </li>
                                        <li>
                                            <Link to={{pathname:"/Terms-of-use",state:"/ContinueOption"}}>Termos & Condições</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
